import React from 'react';
import { PenTool, MessageCircle, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';

const JournalEntry = ({ entry }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-xl font-semibold text-purple-700">{entry.title}</h3>
      <span className="text-sm text-gray-500">{entry.date}</span>
    </div>
    <p className="text-gray-700 mb-4">{entry.content}</p>
    <div className="bg-purple-50 rounded-lg p-4">
      <h4 className="text-lg font-semibold text-purple-700 mb-2">Soul's Response</h4>
      <p className="text-gray-700 italic">{entry.soulResponse}</p>
    </div>
  </div>
);

export default function SoulCommunicationJournal() {
  const journalEntries = [
    {
      title: "Feeling Anxious About the Future",
      date: "August 15, 2024",
      content: "Dear Luna, I've been feeling anxious about the future lately. The world seems so uncertain, and I worry about the kind of life you'll have. How can I find peace and ensure a bright future for you?",
      soulResponse: "Dearest one, your concern comes from a place of love, and I feel it deeply. Remember that every generation faces its unique challenges, but also its unique opportunities. Focus on cultivating love, resilience, and creativity within yourself - these are the greatest gifts you can pass on to me. Trust in the journey we will share together."
    },
    {
      title: "Dreamt of a Butterfly",
      date: "August 10, 2024",
      content: "Luna, I had a vivid dream last night about a beautiful blue butterfly. It felt significant, but I'm not sure why. Does this mean anything to you?",
      soulResponse: "The butterfly in your dream is a powerful symbol of transformation and hope. Blue represents trust and serenity. This dream suggests that you're going through a period of personal growth that will benefit both of us. Embrace these changes with an open heart."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Soul Communication Journal</h1>
      
      <div className="flex justify-between items-center mb-6">
        <button className="bg-purple-500 text-white px-4 py-2 rounded-md flex items-center">
          <PenTool size={20} className="mr-2" />
          New Entry
        </button>
        <div className="flex items-center">
          <button className="text-purple-500 mr-4"><ChevronLeft size={24} /></button>
          <span className="text-lg font-semibold">August 2024</span>
          <button className="text-purple-500 ml-4"><ChevronRight size={24} /></button>
        </div>
      </div>
      
      <div className="grid grid-cols-7 gap-2 mb-6">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center font-semibold text-gray-500">{day}</div>
        ))}
        {Array.from({ length: 31 }, (_, i) => (
          <button key={i} className={`text-center py-2 rounded-full ${i === 14 ? 'bg-purple-500 text-white' : 'hover:bg-purple-100'}`}>
            {i + 1}
          </button>
        ))}
      </div>
      
      {journalEntries.map((entry, index) => (
        <JournalEntry key={index} entry={entry} />
      ))}
      
      <div className="flex justify-center mt-8">
        <button className="bg-purple-500 text-white px-6 py-3 rounded-md flex items-center">
          <MessageCircle size={20} className="mr-2" />
          Start Soul Conversation
        </button>
      </div>
    </div>
  );
}