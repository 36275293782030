import React from 'react';
import { Search, Info } from 'lucide-react';

const AncestorCard = ({ ancestor }) => {
  // Convert ancestor name to lowercase and replace spaces with hyphens for image filename
  const imageFileName = ancestor.name.toLowerCase().replace(/ /g, '-') + '.png';

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <div className="flex items-center mb-4">
        <div className="w-16 h-16 rounded-full mr-4 overflow-hidden">
          <img 
            src={`${process.env.PUBLIC_URL}/${imageFileName}`}
            alt={ancestor.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div>
          <h3 className="text-xl font-semibold text-purple-700">{ancestor.name}</h3>
          <p className="text-gray-500">{ancestor.relation}</p>
        </div>
      </div>
      <p className="text-gray-700 mb-4">{ancestor.connection}</p>
      <div className="bg-yellow-50 rounded-lg p-4">
        <h4 className="text-lg font-semibold text-yellow-700 mb-2">Soul's Reflection</h4>
        <p className="text-gray-700 italic">{ancestor.soulReflection}</p>
      </div>
    </div>
  );
};

export default function AncestralConnections() {
  const ancestors = [
    {
      name: "Elizabeth Rose",
      relation: "Great-Grandmother",
      connection: "Shared a passion for healing and herbal medicine",
      soulReflection: "Elizabeth's wisdom flows through your veins. Her knowledge of nature's remedies resonates deeply with my essence. This connection will guide our journey in holistic well-being."
    },
    {
      name: "James Thompson",
      relation: "Great-Great-Grandfather",
      connection: "Was an explorer and cartographer",
      soulReflection: "James's spirit of adventure and discovery aligns with my curiosity about the world. His courage will inspire our future explorations, both physical and spiritual."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Ancestral Connections</h1>
      
      <div className="bg-purple-100 rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold text-purple-700 mb-2">Explore Your Ancestral Ties</h2>
        <p className="text-gray-700">
          Discover the connections between your chosen soul and your ancestors. 
          These links can provide insight into your child's potential traits, talents, and life path.
        </p>
      </div>
      
      <div className="mb-6">
        <div className="relative">
          <input 
            type="text" 
            placeholder="Search ancestors..." 
            className="w-full p-3 pl-10 border-2 border-purple-300 rounded-md focus:border-purple-500 focus:outline-none"
          />
          <Search className="absolute left-3 top-3 text-purple-400" size={24} />
        </div>
      </div>
      
      {ancestors.map((ancestor, index) => (
        <AncestorCard key={index} ancestor={ancestor} />
      ))}
      
      <div className="flex justify-center mt-8">
        <button className="bg-purple-500 text-white px-6 py-3 rounded-md flex items-center">
          <Info size={20} className="mr-2" />
          Learn More About Ancestral Connections
        </button>
      </div>
    </div>
  );
}