import React from 'react';
import { Search, Filter, ChevronDown } from 'lucide-react';

const SoulCard = ({ soul }) => (
  <div className="border border-gray-200 rounded-lg p-6 bg-white hover:shadow-lg transition-shadow">
    <div className="flex mb-6">
      <img 
        src={`${process.env.PUBLIC_URL}/${soul.id}.png`} 
        alt={`AI generated representation of ${soul.name}`} 
        className="w-72 h-48 object-cover rounded-md mr-6" 
      />
      <div className="flex flex-col justify-between">
        <div>
          <h3 className="text-2xl font-bold text-purple-700 mb-2">{soul.name}</h3>
          <p className="text-sm text-gray-600 mb-2">{soul.lifeMission}</p>
          <div className="flex space-x-2 mb-2">
            {soul.elementalConnections.map((element, index) => (
              <span key={index} className="text-2xl">{element}</span>
            ))}
          </div>
        </div>
        <button className="bg-purple-500 text-white px-4 py-2 rounded-md font-semibold hover:bg-purple-600 transition-colors">
          Connect with {soul.name}
        </button>
      </div>
    </div>
    
    <div className="grid grid-cols-2 gap-4">
      <AttributeSection title="Personality Traits" items={soul.personalityTraits} bgColor="bg-blue-50" textColor="text-blue-700" />
      <AttributeSection title="Symbolic Associations" items={soul.symbolicAssociations} bgColor="bg-green-50" textColor="text-green-700" />
      <AttributeSection title="Name Meanings" items={soul.nameMeanings} bgColor="bg-yellow-50" textColor="text-yellow-700" />
      <AttributeSection title="Future Aspirations" items={soul.futureAspirations} bgColor="bg-pink-50" textColor="text-pink-700" />
      <AttributeSection title="Past Histories" items={soul.pastHistories} bgColor="bg-purple-50" textColor="text-purple-700" />
      <AttributeSection title="Visualization Prompts" items={soul.visualizationPrompts} bgColor="bg-indigo-50" textColor="text-indigo-700" />
      <AttributeSection title="Relationships" items={soul.relationships} bgColor="bg-red-50" textColor="text-red-700" />
      <AttributeSection title="Custom Attributes" items={soul.customAttributes} bgColor="bg-gray-50" textColor="text-gray-700" />
    </div>
  </div>
);

const AttributeSection = ({ title, items, bgColor, textColor }) => (
  <div className={`${bgColor} p-3 rounded-md`}>
    <h4 className={`font-semibold ${textColor} mb-2`}>{title}</h4>
    <ul className="list-disc list-inside text-sm text-gray-700">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

export default function SoulBrowser() {
  const soulData = [
    {
      id: "luna123",
      name: "Luna",
      lifeMission: "To inspire others through courage, exploration, and creative expression",
      elementalConnections: ["🌙", "🌊"],
      personalityTraits: ["Creative", "Empathetic", "Intuitive"],
      symbolicAssociations: ["Owl", "Crescent Moon", "Silver Mirror"],
      nameMeanings: ["Moon", "Light in darkness", "Intuition"],
      futureAspirations: ["Become a renowned artist", "Establish a healing retreat", "Write a bestselling novel"],
      pastHistories: ["Healer in ancient Egypt", "Poet in Renaissance Italy", "Explorer in the Age of Discovery"],
      visualizationPrompts: ["Painting under a full moon", "Swimming in a crystal-clear lake", "Meditating in a sacred grove"],
      relationships: ["Soulmate connection with Orion", "Mentor to younger souls", "Part of a soul group focused on creativity"],
      customAttributes: ["Favorite color: Indigo", "Spirit animal: Wolf", "Preferred artistic medium: Watercolors"]
    },
    {
      id: "zephyr456",
      name: "Zephyr",
      lifeMission: "To innovate and implement sustainable solutions for a harmonious world",
      elementalConnections: ["💨", "🌿"],
      personalityTraits: ["Analytical", "Visionary", "Determined"],
      symbolicAssociations: ["Eagle", "Wind Turbine", "Redwood Tree"],
      nameMeanings: ["West wind", "Bringer of change", "Gentle breeze"],
      futureAspirations: ["Develop revolutionary clean energy technology", "Lead global sustainability movement", "Create a self-sustaining eco-city"],
      pastHistories: ["Inventor in Industrial Revolution", "Native American shaman", "Environmental scientist in 20th century"],
      visualizationPrompts: ["Soaring over wind farms", "Planting seeds in a vast field", "Presenting a world-changing invention"],
      relationships: ["Twin flame with Terra", "Guide for souls passionate about Earth stewardship", "Part of a collective working on planetary healing"],
      customAttributes: ["Favorite element: Air", "Spirit plant: Bamboo", "Preferred problem-solving approach: Systems thinking"]
    },
    {
      id: "nova789",
      name: "Nova",
      lifeMission: "To illuminate paths of knowledge and spark curiosity in the mysteries of the universe",
      elementalConnections: ["🔥", "✨"],
      personalityTraits: ["Curious", "Energetic", "Adaptable"],
      symbolicAssociations: ["Phoenix", "Supernova", "Ancient Library"],
      nameMeanings: ["New star", "Bright explosion", "Sudden change"],
      futureAspirations: ["Make groundbreaking scientific discoveries", "Inspire a new generation of thinkers", "Bridge science and spirituality"],
      pastHistories: ["Alchemist in medieval times", "Astronomer in ancient Greece", "Quantum physicist in modern era"],
      visualizationPrompts: ["Gazing at a star-filled sky", "Unlocking an ancient cosmic secret", "Teaching in a futuristic classroom"],
      relationships: ["Cosmic connection with Celeste", "Mentor for souls drawn to unraveling universal mysteries", "Part of a think tank of innovative souls"],
      customAttributes: ["Favorite phenomenon: Solar flares", "Spirit constellation: Orion", "Preferred learning style: Experiential"]
    }
  ];

  return (
    <div className="p-4 max-w-6xl mx-auto bg-white">
      <h1 className="text-4xl font-bold mb-6 text-center text-purple-800">Soul Explorer</h1>
      
      <div className="flex flex-wrap mb-6 gap-2">
        <div className="relative flex-grow">
          <input 
            type="text" 
            placeholder="Search souls..." 
            className="w-full p-3 pl-10 border-2 border-purple-300 rounded-md focus:border-purple-500 focus:outline-none"
          />
          <Search className="absolute left-3 top-3 text-purple-400" size={24} />
        </div>
        <button className="flex items-center bg-purple-500 text-white px-6 py-3 rounded-md font-bold hover:bg-purple-600 transition-colors">
          <Filter size={20} className="mr-2" />
          Filters
        </button>
      </div>
      
      <div className="space-y-8">
        {soulData.map((soul, index) => (
          <SoulCard key={index} soul={soul} />
        ))}
      </div>
      
      <div className="flex justify-center mt-8">
        <button className="px-6 py-3 bg-purple-500 text-white rounded-md font-bold flex items-center hover:bg-purple-600 transition-colors">
          Load More Souls
          <ChevronDown size={20} className="ml-2" />
        </button>
      </div>
    </div>
  );
}