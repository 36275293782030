import React, { useState } from 'react';
import { Search, Book, Eye, Users, Menu as MenuIcon } from 'lucide-react';

const NavItem = ({ icon: Icon, label, isActive, onClick, isMobile }) => (
  <button
    className={`flex items-center px-4 py-2 rounded-md w-full ${
      isActive ? 'bg-purple-500 text-white' : 'text-gray-600 hover:bg-purple-100'
    }`}
    onClick={onClick}
  >
    <Icon size={20} className="mr-2" />
    <span className={isMobile ? 'inline' : 'hidden md:inline'}>{label}</span>
  </button>
);

export default function AppNavigation({ activePage, setActivePage }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { icon: Search, label: 'Browser', id: 'browser' },
    { icon: Book, label: 'Journal', id: 'journal' },
    { icon: Eye, label: 'Life', id: 'visualization' },
    { icon: Users, label: 'Ancestral', id: 'ancestral' },
  ];

  const goToHomePage = () => {
    setActivePage('browser');
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <button 
              onClick={goToHomePage}
              className="flex items-center focus:outline-none"
            >
              <img 
                src={`${process.env.PUBLIC_URL}/logo.png`} 
                alt="Soul Connection Logo" 
                className="h-8 w-8 mr-2"
              />
              <h1 className="text-xl font-bold text-purple-700">Soul Connection</h1>
            </button>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex space-x-4">
            {navItems.map((item) => (
              <NavItem
                key={item.id}
                icon={item.icon}
                label={item.label}
                isActive={activePage === item.id}
                onClick={() => setActivePage(item.id)}
              />
            ))}
          </div>
          
          {/* Mobile Navigation Toggle */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="flex items-center text-gray-600 hover:text-purple-500 focus:outline-none"
            >
              <MenuIcon size={28} className="mr-2" /> {/* Increased icon size */}
              <span className="text-base font-medium">Menu</span> {/* Increased font size */}
            </button>
          </div>
        </div>
        
        {/* Mobile Navigation Menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            {navItems.map((item) => (
              <NavItem
                key={item.id}
                icon={item.icon}
                label={item.label}
                isActive={activePage === item.id}
                onClick={() => {
                  setActivePage(item.id);
                  setIsMenuOpen(false);
                }}
                isMobile={true}
              />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}