import React from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2 } from 'lucide-react';

const VisualizationScene = ({ scene, index }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <h3 className="text-xl font-semibold text-purple-700 mb-4">{scene.title}</h3>
    <div className="h-64 rounded-lg mb-4 overflow-hidden">
      <img 
        src={`${process.env.PUBLIC_URL}/scene-${index + 1}.png`}
        alt={scene.title}
        className="w-full h-full object-cover"
      />
    </div>
    <p className="text-gray-700 mb-4">{scene.description}</p>
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <button className="text-purple-500 mr-4"><SkipBack size={24} /></button>
        <button className="bg-purple-500 text-white p-2 rounded-full"><Play size={24} /></button>
        <button className="text-purple-500 ml-4"><SkipForward size={24} /></button>
      </div>
      <div className="flex items-center">
        <Volume2 size={24} className="text-purple-500 mr-2" />
        <input type="range" className="w-24" />
      </div>
    </div>
  </div>
);

export default function LifeVisualization() {
  const scenes = [
    {
      title: "First Steps",
      description: "Visualize your child taking their first steps in a sunlit meadow. Feel the soft grass beneath their feet and the gentle breeze carrying the scent of wildflowers."
    },
    {
      title: "School Days",
      description: "See your child on their first day of school, excited and curious. Picture them making new friends and discovering their passion for learning."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Life Visualization</h1>
      
      <div className="bg-purple-100 rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold text-purple-700 mb-2">Welcome to Life Visualization</h2>
        <p className="text-gray-700">
          This feature allows you to visually explore potential moments in your future child's life. 
          Each scene is inspired by your chosen soul's attributes and potential future. 
          Remember, these are possibilities to inspire and guide you, not fixed destinies.
        </p>
      </div>
      
      {scenes.map((scene, index) => (
        <VisualizationScene key={index} scene={scene} index={index} />
      ))}
      
      <div className="flex justify-center mt-8">
        <button className="bg-purple-500 text-white px-6 py-3 rounded-md">
          Create Custom Visualization
        </button>
      </div>
    </div>
  );
}