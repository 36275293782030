import React, { useState } from 'react';
import AppNavigation from './components/AppNavigation';
import SoulBrowser from './components/SoulBrowser';
import SoulCommunicationJournal from './components/SoulCommunicationJournal';
import LifeVisualization from './components/LifeVisualization';
import AncestralConnections from './components/AncestralConnections';
import './index.css'; // Make sure this import is present

export default function App() {
  const [activePage, setActivePage] = useState('browser');

  const renderActivePage = () => {
    switch (activePage) {
      case 'browser':
        return <SoulBrowser />;
      case 'journal':
        return <SoulCommunicationJournal />;
      case 'visualization':
        return <LifeVisualization />;
      case 'ancestral':
        return <AncestralConnections />;
      default:
        return <SoulBrowser />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <AppNavigation activePage={activePage} setActivePage={setActivePage} />
      <main className="pt-6">
        {renderActivePage()}
      </main>
    </div>
  );
}